import React, { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Form } from "@rjsf/bootstrap-4";
import { Helmet } from "react-helmet";
import validator from "@rjsf/validator-ajv8";

import { UpdateProfileSchema } from "./UpdateProfileSchema";
import { UpdateProfileUiSchema } from "./UpdateProfileUiSchema";
import FirebaseService from "../../../../actions/firebase/FirebaseActions";
import { PrimaryButton } from "../../../common/button/Buttons";
import { useUser } from "../../../../hooks/useUser";
import { UserProfile } from "../../../../models/userProfile/UserProfile";

interface Props {
  userId: string;
}
const UserProfileSettings: FunctionComponent<Props> = ({ userId }) => {
  const { user } = useUser(userId);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(user?.userProfile);

  useEffect(() => {
    setUserProfile(user?.userProfile);
  }, [user]);

  const updateProfile = (formData: unknown): void => {
    const { name, email } = formData as {
      name: string;
      email: string;
    };

    FirebaseService.updateUserProfile(userId, { name, email })
      .then(() => {
        toast.success("Ihr Profil wurde aktualisiert");
        //setUserProfile({ name, email });
      })
      .catch((error) => {
        toast.error(`Fehler: ${error}`);
      });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Webtie Anmeldungen - Profil</title>
      </Helmet>
      <Form
        formData={userProfile}
        validator={validator}
        uiSchema={UpdateProfileUiSchema}
        schema={UpdateProfileSchema}
        onSubmit={(e: any) => updateProfile(e.formData)}>
        <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
      </Form>
    </div>
  );
};

export default UserProfileSettings;
