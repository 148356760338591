import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { User } from "../../models/user/User";

interface AccessControlModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (userId: string, allowedUsers: Record<string, boolean>) => void;
  allUsers: User[]; // All users to display in the modal
  userId?: string; // Current user ID being edited
  allowedUsers?: Record<string, boolean>; // Access control for the current user
}

export const AccessControlModal: FunctionComponent<AccessControlModalProps> = ({
  show,
  onClose,
  onSave,
  allUsers,
  userId,
  allowedUsers = {}
}) => {
  const [editedAllowedUsers, setEditedAllowedUsers] = useState<Record<string, boolean>>(allowedUsers);

  useEffect(() => {
    setEditedAllowedUsers(allowedUsers);
  }, [allowedUsers]);

  const handleToggleAccess = (id: string): void => {
    setEditedAllowedUsers((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleSubmit = (): void => {
    if (userId) {
      onSave(userId, editedAllowedUsers);
    }
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Manage Access Control</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Access</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.userProfile?.name}</td>
                  <td>{user.userProfile?.email || user.email}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={editedAllowedUsers[user.id] || false}
                      onChange={() => handleToggleAccess(user.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
