import { JSONSchema7 } from "json-schema";

export const UpdateProfileSchema: JSONSchema7 = {
  title: "Benutzerprofil",
  description:
    "Diese Informationen dienen ausschließlich der Anzeige in Ihrem Profil. Sollten Sie über mehrere " +
    "Benutzer- oder Anmeldeformulare verfügen, können Sie diese dadurch einfacher identifizieren.",
  type: "object",
  required: ["name"],
  properties: {
    name: {
      type: "string",
      title: "Anzeigename",
      description: "z.B. Name der Spielgruppe, des Anmeldeformulars oder einer Person"
    },
    email: {
      type: "string",
      title: "E-Mail-Adresse"
    }
  }
};
