import React, { ReactElement } from "react";

import { User } from "../../models/user/User";
import { useUser } from "../../hooks/useUser";
import { EagerUserEntry } from "./EagerUserEntry";

interface LazyUserEntryParams {
  onClick: (user?: User) => void;
  userId: string;
  onManageAccess?: () => void;
}

/**
 * Loads a User while rendering this component
 */
export function LazyUserEntry({ userId, onClick, onManageAccess }: LazyUserEntryParams): ReactElement {
  const { user } = useUser(userId);

  return <EagerUserEntry user={user} onClick={() => onClick(user)} onManageAccess={onManageAccess} />;
}
