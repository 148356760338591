import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";

export const useAccessibleUsersIds = (userId?: string): { userIds: string[]; isLoading: boolean } => {
  const [userIds, setUserIds] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    if (userId) {
      FirebaseService.getAccessibleUserIds(userId).then((userIds) => {
        setUserIds(userIds);
        setLoading(false);
      });
    }
  }, [userId]);

  return { userIds, isLoading };
};
