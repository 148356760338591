import React, { FunctionComponent, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";

import { useAuthentication } from "../../hooks/useAuthentication";
import { ContentLayout } from "../common/Layout";
import { useUserContext } from "../../contexts/UserContext";
import { User } from "../../models/user/User";
import { useAccessibleUsersIds } from "../../hooks/useAccessibleUsersIds";
import { useAuthUser } from "../../hooks/useAuthUser";
import { LazyUserEntry } from "./LazyUserEntry";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const UserManagement: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const firebaseAuthUser = useAuthUser();
  const { setActiveUser } = useUserContext();
  const { userIds, isLoading: isAccessibleUsersLoading } = useAccessibleUsersIds(firebaseAuthUser?.uid);

  useAuthentication(props.isAuthenticated);

  useEffect(() => {
    if (!props.isAuthenticated) {
      history.push("/login");
    }
  }, [props.isAuthenticated, history]);

  if (!firebaseAuthUser) {
    return <></>;
  }

  const handleUserSelect = (user?: User): void => {
    if (!user) {
      return;
    }
    setActiveUser(user);
    history.push(`/user/${user.id}/dashboard`);
  };

  return (
    <ContentLayout
      title="Adminzentrale"
      siteTitle="Spielgruppe Online - User Verwaltung"
      isLoading={isAccessibleUsersLoading}>
      <Row className="py-5">
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>E-Mail</th>
              </tr>
            </thead>
            <tbody>
              <LazyUserEntry onClick={(user) => handleUserSelect(user)} userId={firebaseAuthUser.uid} />
              {userIds.map((userId, index) => (
                <LazyUserEntry key={index} onClick={(user) => handleUserSelect(user)} userId={userId} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default UserManagement;
