import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { Col, Row, Table, Button } from "react-bootstrap";

import { useAuthentication } from "../../hooks/useAuthentication";
import { ContentLayout } from "../common/Layout";
import { useUserContext } from "../../contexts/UserContext";
import { useUsers } from "../../hooks/useUsers";
import FirebaseActions from "../../actions/firebase/FirebaseActions";
import { AccessControlModal } from "./AccessControlModal";

interface MatchParams {
  userId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}

const Admin: FunctionComponent<Props> = ({ isAuthenticated }) => {
  const history = useHistory();
  const { setActiveUser } = useUserContext();
  const [users, usersLoading, usersError] = useUsers();
  const [modalUserId, setModalUserId] = useState<string | null>(null);
  const [modalAccessControl, setModalAccessControl] = useState<Record<string, boolean>>({});
  const [isModalOpen, setModalOpen] = useState(false);

  useAuthentication(isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [isAuthenticated, history]);

  if (usersError) {
    console.error(usersError);
  }

  const handleUserSelect = (userId: string): void => {
    const user = users.find((u) => u.id === userId);
    if (user) {
      setActiveUser(user);
      history.push(`/user/${userId}/dashboard`);
    }
  };

  const openModal = async (userId: string): Promise<void> => {
    try {
      const accessControlData = await FirebaseActions.getAccessControlData(userId);
      setModalUserId(userId);
      setModalAccessControl(accessControlData.allowedUsers);
      setModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch access control data:", error);
    }
  };

  const closeModal = (): void => {
    setModalUserId(null);
    setModalOpen(false);
  };

  const handleSaveAccessControl = async (
    userId: string,
    updatedAccessControl: Record<string, boolean>
  ): Promise<void> => {
    try {
      await FirebaseActions.updateAccessControl(userId, updatedAccessControl);
      console.log("Access control updated successfully");
      closeModal();
    } catch (error) {
      console.error("Failed to update access control:", error);
    }
  };

  return (
    <ContentLayout title="Admin Dashboard" siteTitle="Admin Control Panel" isLoading={usersLoading}>
      <Row className="py-5">
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>E-Mail</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.userProfile?.name}</td>
                  <td>{user.userProfile?.email || user.email}</td>
                  <td>
                    <Button className={"mb-2"} variant="primary" size="sm" onClick={() => handleUserSelect(user.id)}>
                      View Dashboard
                    </Button>
                    <Button variant="secondary" size="sm" onClick={() => openModal(user.id)}>
                      Manage Access
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      {modalUserId && (
        <AccessControlModal
          show={isModalOpen}
          onClose={closeModal}
          onSave={handleSaveAccessControl}
          userId={modalUserId}
          allowedUsers={modalAccessControl}
          allUsers={users}
        />
      )}
    </ContentLayout>
  );
};

export default Admin;
