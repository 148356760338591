import React, { ReactElement } from "react";

import { User } from "../../models/user/User";

interface Props {
  user?: User;
  onClick: () => void;
  onManageAccess?: () => void;
}

/**
 * Renders a user entry with actions
 */
export function EagerUserEntry({ user, onClick, onManageAccess }: Props): ReactElement {
  return (
    <tr style={{ cursor: "pointer" }}>
      <td onClick={onClick}>{user?.id}</td>
      <td onClick={onClick}>{user?.userProfile?.name}</td>
      <td onClick={onClick}>{user?.userProfile?.email || user?.email}</td>
      {onManageAccess && (
        <td>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onManageAccess?.();
            }}
            className="btn btn-sm btn-primary">
            Manage Access
          </button>
        </td>
      )}
    </tr>
  );
}
