import { JSONSchema7 } from "json-schema";
import firebase from "firebase/compat/app";

import { db, firebaseAppAuth } from "../../lib/firebase";
import Limit from "../../models/limit/Limit";
import Registration, { UpdateRegistration } from "../../models/registration/Registration";
import FormSchemaService from "../schema/FormSchemaActions";
import { DocumentObserver, QueryObserver } from "./FirestoreObserver";
import { FirebaseSnapshotMapper } from "./FirebaseSnapshotMapper";
import { MailSettings } from "../../models/userData/settings/MailSettings";
import { UserData } from "../../models/userData/UserData";
import { RawFormSettings } from "../../models/userData/settings/RawFormSettings";
import Apis from "../../api/Apis";
import AddLimit from "../../models/limit/AddLimit";
import { User } from "../../models/user/User";
import { UserProfile } from "../../models/userProfile/UserProfile";
import { AccessControlData } from "../../models/AccessControl/AccessControl";

class FirebaseActions {
  private firebaseApi = Apis.getFirebaseApi();

  public getSchemaProperties = async (userId: string): Promise<string[]> => {
    const schema = await FormSchemaService.getFormSchemaByUserId(userId);
    const formSchema = schema.formSchema;
    const schemaProperties = formSchema.properties;
    const viewProperties: string[] = [];
    if (schemaProperties) {
      Object.keys(schemaProperties).forEach(function (key) {
        const property = schemaProperties[key] as JSONSchema7;
        if (property.type) {
          viewProperties.push(key);
        }
      });
    }
    return viewProperties;
  };

  public streamRegistration = (
    userId: string,
    registrationId: string,
    observer: DocumentObserver<firebase.firestore.DocumentData>
  ): (() => void) => {
    return db.collection("users").doc(userId).collection("registrations").doc(registrationId).onSnapshot(observer);
  };

  public streamUsers = (observer: QueryObserver<firebase.firestore.DocumentData>): (() => void) => {
    return db.collection("users").onSnapshot(observer);
  };

  public getUser = async (userId: string): Promise<User> => {
    const user = await db.collection("users").doc(userId).get();
    return FirebaseSnapshotMapper.toUser(user);
  };

  public getAccessibleUserIds = async (userId: string): Promise<string[]> => {
    const accessControlEntry = await db.collection("accessControl").doc(userId).get();
    return FirebaseSnapshotMapper.toAllowedUserIds(accessControlEntry);
  };

  public streamRegistrations = (
    userId: string,
    observer: QueryObserver<firebase.firestore.DocumentData>
  ): (() => void) => {
    return db
      .collection("users")
      .doc(userId)
      .collection("registrations")
      .orderBy("creation.createdAt")
      .onSnapshot(observer);
  };

  public streamUserData = (
    userId: string,
    observer: DocumentObserver<firebase.firestore.DocumentData>
  ): (() => void) => {
    return db.collection("users").doc(userId).onSnapshot(observer);
  };

  public getUserData = async (userId: string): Promise<UserData> => {
    const doc = await db.collection("users").doc(userId).get();
    return FirebaseSnapshotMapper.toUserData(doc);
  };

  public getAccessControlData = async (userId: string): Promise<AccessControlData> => {
    const doc = await db.collection("accessControl").doc(userId).get();
    return FirebaseSnapshotMapper.toAccessControlData(doc);
  };

  public streamLimits = (userId: string, observer: QueryObserver<firebase.firestore.DocumentData>): (() => void) => {
    return db.collection("users").doc(userId).collection("limits").orderBy("property").onSnapshot(observer);
  };

  public streamLimit = (
    userId: string,
    limitId: string,
    observer: DocumentObserver<firebase.firestore.DocumentData>
  ): (() => void) => {
    return db.collection("users").doc(userId).collection("limits").doc(limitId).onSnapshot(observer);
  };

  public getRegistration = async (userId: string, fileId: string): Promise<Registration> => {
    const doc = await db.collection("users").doc(userId).collection("registrations").doc(fileId).get();
    return FirebaseSnapshotMapper.toRegistration(doc);
  };

  public createRegistration = async (userId: string, formData: any): Promise<string> => {
    const authToken = await firebaseAppAuth.currentUser?.getIdToken();
    if (!authToken) throw Error("User not signed in");

    return await this.firebaseApi.createRegistration(userId, formData, authToken);
  };

  public updateRegistration = async (userId: string, registration: UpdateRegistration): Promise<void> => {
    const authToken = await firebaseAppAuth.currentUser?.getIdToken();
    if (!authToken) throw Error("User not signed in");
    return await this.firebaseApi.updateRegistration(userId, registration.id, registration.formData, authToken);
  };

  public deleteRegistration = async (userId: string, registrationId: string): Promise<void> => {
    const authToken = await firebaseAppAuth.currentUser?.getIdToken();
    if (!authToken) throw Error("User not signed in");
    return await this.firebaseApi.deleteRegistration(userId, registrationId, authToken);
  };

  public deleteAllRegistrations = async (userId: string): Promise<void> => {
    const authToken = await firebaseAppAuth.currentUser?.getIdToken();
    if (!authToken) throw Error("User not signed in");
    return await this.firebaseApi.deleteAllRegistrations(userId, authToken);
  };

  public getSchemaEndpoint = async (userId: string): Promise<string | undefined> => {
    const userData = await this.getUserData(userId);
    const formSettings = userData.settings.formSettings;
    if (!formSettings) {
      return userData.schemaEndpoint;
    }
    return formSettings.formSchemaEndpoint;
  };

  public deleteLimit = async (userId: string, limitId: string): Promise<void> => {
    return await db.collection("users").doc(userId).collection("limits").doc(limitId).delete();
  };

  public addLimit = async (userId: string, limit: AddLimit): Promise<string> => {
    const firebaseLimit = { property: limit.property, limit: limit.limit, price: limit.price, taken: 0 };
    const response = await db.collection("users").doc(userId).collection("limits").add(firebaseLimit);
    return response.id;
  };

  public updateLimit = async (userId: string, limit: Partial<Limit>): Promise<void> => {
    const firebaseLimit = { property: limit.property, limit: limit.limit, price: limit.price };
    return db.collection("users").doc(userId).collection("limits").doc(limit.id).update(firebaseLimit);
  };

  public addLimitIfNotExist = async (userId: string, limitsProperties: string[]): Promise<void> => {
    const limitSnapshots = await db.collection("users").doc(userId).collection("limits").get();
    const existingLimitProperties = FirebaseSnapshotMapper.toLimits(limitSnapshots).map((limit) => limit.property);

    const promises = limitsProperties
      .filter((limitProperty) => !existingLimitProperties.includes(limitProperty))
      .map(async (limitProperty) => {
        const firebaseLimit = { property: limitProperty, limit: 0, price: 0, taken: 0 };
        await db.collection("users").doc(userId).collection("limits").add(firebaseLimit);
      });

    await Promise.all(promises);
  };

  public updateEmailSettings = async (userId: string, mailSettings: MailSettings): Promise<void> => {
    return db.collection("users").doc(userId).update({ "settings.mailSettings": mailSettings });
  };

  public updateFormSettings = async (userId: string, formSettings: RawFormSettings): Promise<void> => {
    return db.collection("users").doc(userId).update({ "settings.formSettings": formSettings });
  };

  public updateUserProfile = async (userId: string, userProfile: UserProfile): Promise<void> => {
    return db.collection("users").doc(userId).update({ userProfile: userProfile });
  };

  public updatePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
    const user = firebaseAppAuth.currentUser;
    if (!user) throw Error("User not signed in");

    const email = user.email;
    if (!email) {
      throw Error("Could not find email address for user");
    }

    const credentials = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
    await user.reauthenticateWithCredential(credentials);

    return user.updatePassword(newPassword).catch((error) => {
      if (error.message) {
        throw Error(error.message);
      }
    });
  };

  public updateEmail = async (currentEmail: string, currentPassword: string, newEmail: string): Promise<void> => {
    const user = firebaseAppAuth.currentUser;
    if (!user) throw Error("User not signed in");

    const credentials = firebase.auth.EmailAuthProvider.credential(currentEmail, currentPassword);
    await user.reauthenticateWithCredential(credentials);

    return user.updateEmail(newEmail).catch((error) => {
      if (error.message) {
        throw Error(error.message);
      }
    });
  };

  async updateAccessControl(userId: string, allowedUsers: Record<string, boolean>): Promise<void> {
    const db = firebase.firestore();
    const accessControlRef = db.collection("accessControl").doc(userId);

    await accessControlRef.set(
      { allowedUsers },
      { merge: true } // Merge to avoid overwriting other fields
    );
  }
}

export default new FirebaseActions();
