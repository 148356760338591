import { useEffect, useState } from "react";

import FirebaseService from "../actions/firebase/FirebaseActions";
import { User } from "../models/user/User";

export const useUser = (userId: string): { user: User | undefined; isLoading: boolean } => {
  const [user, setUser] = useState<User>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    FirebaseService.getUser(userId).then((userIds) => {
      setUser(userIds);
      setLoading(false);
    });
  }, [userId]);

  return { user, isLoading };
};
